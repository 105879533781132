import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Header from './Header'
import PageHead from './PageHead'
import Branding from './Branding'

const Layout = ({ children }) => (
  <Fragment>
    <Branding />
    <PageHead />
    <Header />
    <main>{children}</main>
  </Fragment>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
