import { Link } from 'gatsby'
import React from 'react'

const Header = () => (
  <header>
    <div className="container">
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <div className="brand">
              <span className="brand__title">Chris Atkin</span>
              <span className="brand__subtitle">Software engineer</span>
            </div>
          </Link>
        </div>
      </nav>
    </div>
  </header>
)

export default Header
