import React, { Fragment } from 'react'

const Branding = () => (
  <Fragment>
    <div className="branding branding--left" />
    <div className="branding branding--right" />
  </Fragment>
)

export default Branding
